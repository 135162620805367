<template>
  <div class="teacher">
    <!-- <icon-menu></icon-menu> -->
    <div class="d-flex">
      <import class="pl-2" :doImport="doImport" :parseItems="parseItems"></import>
      <div class="ml-3 mb-3" v-if="[0, 1, 4, 5].includes(user.role)">
        <el-button size="small" type="success" plain @click="exportImgs">导出讲师培训图片</el-button>
      </div>

      <div class="ml-3 mb-3" v-if="[0, 1, 4, 5].includes(user.role)">
        <el-button v-if="[0, 1, 4, 5].includes(user.role)" type="success" plain
          @click="exportTeachs">导出讲师授课记录</el-button>
      </div>

      <!-- <div class="pl-3" v-if="(user.username = 'admin')">
        <span class="pr-2 pl-2 fs-9 import-ex">导出讲师排课数据</span>
        <el-button type="primary" @click="exprot">导出</el-button>
      </div> -->
    </div>

    <avue-crud :data="data.data" :option="option" v-model="obj" @row-save="create" @row-update="update"
      @row-del="remove" :page.sync="page" @on-load="changPage" @search-change="search" :upload-preview="preview"
      :before-open="beforeOpen" :before-close="beforeClose">
      <template slot-scope="scope" slot="menu">
        <el-button @click="teacherData(scope.row)" icon="el-icon-document" type="text">数据详情</el-button>

        <el-button @click="teachData(scope.row)" icon="el-icon-document" type="text">授课记录</el-button>
      </template>
      <template slot-scope="{}" slot="training_photosForm">
        <!-- <el-button>{{ photos }}</el-button> -->
        <div>
          <div class="label-title">培训图片:</div>
          <el-timeline v-if="photos.length > 0">
            <el-timeline-item v-for="(item, index) in photos" :key="index" :timestamp="'终端客户：' +
              item.terminal_name +
              ' ' +
              '日期：' +
              $filters.formatDateT(item.times)
              " placement="top">
              <el-card>
                <el-image class="mx-1" v-for="img in item.url" :key="img" style="width: 102px; height: 102px"
                  :src="getOssUrl(img)" fit="cover" :preview-src-list="item.url">
                </el-image>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </template>
    </avue-crud>
    <el-dialog :title="obj.name" :visible.sync="dialogVisible" width="80%" align="center" :before-close="handleClose">
      <div class="text-right mb-3 d-flex align-items-center">
        <span class="mr-2 fs-9 ml-auto">当前数据年月份</span>
        <div style="width: 270px">
          <el-date-picker style="width: 100% !important" v-model="customerQuery.year" type="monthrange"
            :clearable="false" value-format="yyyy-MM-dd" placeholder="请选择年月份" />
        </div>
      </div>
      <div class="mb-4">
        <avue-data-panel :option="teacherOption"></avue-data-panel>
      </div>
      <div class="d-flex chart-box">
        <div ref="chart1" class="chart1" style="height: 280px; width: 100%"></div>
        <!-- <div ref="chart2" class="chart2" style="height: 280px; width: 100%"></div> -->
      </div>
      <div class="d-flex">
        <!-- <div ref="chart4" style="height: 280px; width: 100%"></div> -->
      </div>
      <div class="w-100">
        <div ref="chart3" style="height: 280px; width: 100%"></div>
      </div>
      <div class="fs-16 d-flex mt-2">合作机构排名</div>
      <el-table class="mt-3" :data="customers" stripe border style="width: 100%">
        <el-table-column prop="_id.name" label="机构名称">
          <template slot-scope="{ row }">
            <span v-if="row.customer">{{ row.customer.name }}</span>
            <span v-else>客户已删除</span>
          </template>
        </el-table-column>
        <el-table-column prop="days" label="线下授课/天"> </el-table-column>
        <el-table-column prop="hour" label="线上授课/小时"> </el-table-column>
      </el-table>

      <div class="mt-3">
        <el-pagination background @current-change="handleCurrentChange" :current-page.sync="customerQuery.page"
          layout="total, prev, pager, next" :total="customerPage.total">
        </el-pagination>
      </div>

      <div class="detail_table">
        <div class="fs-16 d-flex mt-2">回款明细</div>
        <el-table class="mt-3" :data="detailData" stripe border @sort-change="sortDetailData" style="width: 100%">
          <el-table-column prop="_id.name" label="机构名称" width="300">
            <template slot-scope="{ row }">
              <span v-if="row.customer">{{ row.customer.name }}</span>
              <span v-else>客户已删除</span>
            </template>
          </el-table-column>
          <el-table-column prop="post" label="档期名称"> </el-table-column>
          <el-table-column prop="user" label="经纪人" width="100">
            <template slot-scope="{ row }">
              <span v-if="row.user">{{ row.user.realname }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="order_amount" sortable="custom" label="总金额" width="100">
          </el-table-column>
          <el-table-column prop="received" sortable="custom" label="已回款" width="100">
          </el-table-column>
          <el-table-column prop="remain" sortable="custom" label="未回款" width="100">
          </el-table-column>
        </el-table>
      </div>

      <div class="mt-3">
        <el-pagination background @current-change="changeDetailPage" :current-page.sync="detailPage.page"
          layout="total, prev, pager, next" :total="detailPage.total">
        </el-pagination>
      </div>

      <!-- <div>
        <div class="fs-16 d-flex mt-2">未回款明细</div>
        <el-table
          class="mt-3"
          :data="customers"
          stripe
          border
          style="width: 100%"
        >
          <el-table-column prop="_id.name" label="机构名称">
            <template slot-scope="{ row }">
              <span v-if="row.customer">{{ row.customer.name }}</span>
              <span v-else>客户已删除</span>
            </template>
          </el-table-column>
          <el-table-column prop="days" label="线下授课/天"> </el-table-column>
          <el-table-column prop="hour" label="线上授课/小时"> </el-table-column>
        </el-table>
      </div> -->
    </el-dialog>

    <el-dialog title="导出培训图片" :visible.sync="dialogExport" width="30%" :before-close="handleClose">
      <avue-form v-model="exportForm" :option="exportOption" @submit="exportSubmit"></avue-form>
    </el-dialog>
    <el-dialog title="导出授课记录" :visible.sync="teachExport" width="50%" :before-close="closeTeach">
      <avue-form v-model="teachForm" :option="teachExportOption" @submit="teachSubmit">
        <template #teachers="{ disabled }">
          <div>
            <MultipleSelect :disabled="disabled" filterable :selectOptions="teachers_options"
              v-model="teachForm.teachers" ref="multipleSelect" />
          </div>
        </template>
      </avue-form>
    </el-dialog>

    <el-dialog title="授课记录" :visible.sync="isShowTeach" width="90%" :before-close="handleTeacherClose">
      <div style="height: 900px">
        <avue-crud v-model="teachQuery" :table-loading="teachLoading" :data="teachList" :option="teachoption"
          :page.sync="teachPage" @search-change="teachSearch" @on-load="changTeachPage" @sort-change="sortChange"
          style="height: 100px">
          <template slot="menuRight">
            <div v-loading="teachLoading" class="h6">
              全年满意度 <span class="font-bold mx-2">{{ year_evaluate }}</span>
            </div>
          </template>
          <template slot="timesSearch">
            <el-date-picker v-model="queryTimes" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </template>

          <template slot="citySearch">
            <el-cascader :options="cityOptions" v-model="queryCitys" :props="{ checkStrictly: true }"
              clearable></el-cascader>
          </template>

          <template slot="city" slot-scope="scope">
            <el-tooltip effect="dark" :content="scope.row.city && scope.row.city.join(' | ')" placement="top">
              <div class="text-truncate1">{{ scope.row.city && scope.row.city.join(" | ") }}</div>
            </el-tooltip>
          </template>
          <template slot-scope="scope" slot="times">
            <el-popover placement="top-start" trigger="hover" slot="reference">
              <div style="max-width: 400px">
                {{ cur_time(scope.row) | formatDateT }}
              </div>
              <div slot="reference">
                <div class="text-truncate cursor-pointer" style="width: 100px">
                  {{ cur_time(scope.row) | formatDateT }}
                </div>
              </div>
            </el-popover>
          </template>
          <template slot="terminal_name" slot-scope="scope">
            <el-tooltip effect="dark" :content="_.get(scope.row, 'terminal_name')" placement="top-start">
              <div class="text-truncate cursor-pointer">
                {{ _.get(scope.row, "terminal_name") }}
              </div>
            </el-tooltip>
          </template>
          <template slot="customer" slot-scope="scope">
            <el-tooltip effect="dark" :content="_.get(scope.row, 'customer')" placement="top-start">
              <div class="text-truncate cursor-pointer">
                {{ _.get(scope.row, "customer") }}
              </div>
            </el-tooltip>
          </template>

          <template slot="student_job" slot-scope="scope">
            <el-tooltip effect="dark" :content="_.get(scope.row, 'student_job')" placement="top-start">
              <div class="text-truncate cursor-pointer">
                {{ _.get(scope.row, "student_job") }}
              </div>
            </el-tooltip>
          </template>
          <template slot="post" slot-scope="scope">
            <el-tooltip effect="dark" :content="_.get(scope.row, 'post')" placement="top-start">
              <div class="text-truncate cursor-pointer">
                {{ _.get(scope.row, "post") }}
              </div>
            </el-tooltip>
          </template>

          <template slot-scope="scope" slot="way">
            <el-tag :type="scope.row.way == 0 ? 'primary' : 'success'">
              {{ scope.row.way == 0 ? "线上" : "线下" }}</el-tag>
          </template>

          <template slot-scope="scope" slot="fate">
            <span v-if="scope.row.way == 1"> {{ scope.row.fate }} 天</span>
            <span v-else> {{ scope.row.hour }} 小时</span>
          </template>
        </avue-crud>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import * as dayjs from "dayjs";
  import { saver } from "../../plugins/saver";

  export default {
    data() {
      const validEmail = (rule, value, callback) => {
        const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的邮箱"));
        }
      };
      return {
        teacherOption: {},
        cityOptions: [],
        dialogExport: false,
        teachExport: false,
        teachForm: {
          times: [],
          teachers: [],
        },
        exportForm: {
          teacherIds: [],
        },
        isShowTeach: false,
        teachList: [],
        teacheQuery: {},
        customerCount: null,
        userData: null,
        teachingDay: null,
        dialogVisible: false,
        tabs: true,
        teachersId: null,
        recommendData: null,
        form: {},
        showPhotos: false,
        obj: {
          quotation: [
            {
              title: "公开课对外报价",
              unit: "元/6小时",
            },
            {
              title: "内训课对外报价",
              unit: "元/6小时",
            },
            {
              title: "半天课对外报价",
              unit: "元/3小时",
            },
            {
              title: "1.5天课对外报价",
              unit: "元/9小时",
            },
            {
              title: "直播课对外报价",
              unit: "元/1小时",
            },
            {
              title: "直播课对外报价",
              unit: "元/6小时",
            },
            {
              title: "录播课对外报价",
              unit: "元/1小时",
            },
            {
              title: "异地直播课报价",
              unit: "元/1小时",
            },
            {
              title: "异地直播课报价",
              unit: "元/6小时",
            },
            {
              title: "异地录播课报价",
              unit: "元/1小时",
            },
            {
              title: "线上微沙龙报价",
              unit: "元/1场次",
            },
            {
              title: "线下微沙龙报价",
              unit: "元/1场次",
            },
            {
              title: "辅导项目报价",
              unit: "元/天",
            },
            {
              title: "其他类别报价",
              unit: "",
            },
          ],
          // training_photos: [],
        },

        exportOption: {
          translate: false,
          column: [
            {
              label: "选择讲师",
              prop: "teacherIds",
              type: "select",
              span: 24,
              props: {
                label: "name",
                value: "_id",
              },
              multiple: true,
              all: true,
              dicUrl: `teachers/option`,
              filterable: true,
              rules: [
                {
                  required: true,
                  message: "请选择导出的内容",
                  trigger: "blur",
                },
              ],
            },
            // {
            //   label: "邮箱",
            //   prop: "email",
            //   slot: true,
            //   rules: [
            //     {
            //       required: true,
            //       message: "请填写邮箱",
            //       trigger: "blur",
            //     },
            //     { validator: validEmail, trigger: "blur", required: true },
            //   ],
            // },
          ],
        },
        teachExportOption: {
          translate: false,
          column: [
            {
              label: "选择讲师",
              prop: "teachers",
              type: "select",
              span: 24,
              props: {
                label: "name",
                value: "_id",
              },
              multiple: true,
              all: true,
              dicUrl: `teachers/option`,
              slot: true,
              filterable: true,
              rules: [
                {
                  required: true,
                  message: "请选择需要导出的讲师",
                  trigger: "blur",
                },
              ],
            },
            {
              label: "选择时间",
              prop: "times",
              type: "daterange",
              startPlaceholder: "开始日期",
              endPlaceholder: "结束日期",
              span: 24,
            },
          ],
        },
        quotation: [
          {
            title: "公开课对外报价",
            unit: "元/6小时",
          },
          {
            title: "内训课对外报价",
            unit: "元/6小时",
          },
          {
            title: "半天课对外报价",
            unit: "元/3小时",
          },
          {
            title: "1.5天课对外报价",
            unit: "元/9小时",
          },
          {
            title: "直播课对外报价",
            unit: "元/1小时",
          },
          {
            title: "直播课对外报价",
            unit: "元/6小时",
          },
          {
            title: "录播课对外报价",
            unit: "元/1小时",
          },
          {
            title: "异地直播课报价",
            unit: "元/1小时",
          },
          {
            title: "异地直播课报价",
            unit: "元/6小时",
          },
          {
            title: "异地录播课报价",
            unit: "元/1小时",
          },
          {
            title: "线上微沙龙报价",
            unit: "元/1场次",
          },
          {
            title: "线下微沙龙报价",
            unit: "元/1场次",
          },
          {
            title: "辅导项目报价",
            unit: "元/天",
          },
          {
            title: "其他类别报价",
            unit: "",
          },
        ],
        photos: [],
        year_evaluate: 0,
        importData: [],
        customerQuery: {
          year: [
            dayjs().startOf("year").toDate(),
            dayjs().endOf("month").toDate(),
          ],
          limit: 10,
          page: 1,
        },
        customerPage: {
          total: 0,
        },
        customers: [],
        query: {
          limit: 10,
          // collation: {locale: 'zh'},
          sort: { grade: 1 },
        },
        teachers_options: [],
        page: {
          total: 0,
        },
        teachPage: {
          total: 0,
          pageSize: 100,
          currentPage: 1,
        },
        teachQuery: {
          where: {},
        },
        teachSort: {
          times: -1,
        },
        teachLoading: false,
        queryTimes: [],
        queryCitys: [],
        teach_user: {},
        data: [],
        showKey: "",
        showDetail: false,
        detailData: [],
        sort_where: {
          order_amount: 1,
          received: 1,
          remain: -1,
        },
        detailPage: {
          page: 1,
          limit: 10,
        },
        // receiveddetailPage: {
        //   page: 1,
        //   limit: 10,
        // },
      };
    },
    computed: {
      option() {
        const user = this.$store?.state?.user || {};

        const isHide = [0, 6].includes(user.role);

        return {
          border: true,
          dialogWidth: 900,
          menuWidth: 450,
          viewBtn: true,
          align: "center",
          searchMenuSpan: 4,
          editBtn: isHide,
          delBtn: isHide,
          dialogClickModal: false,
          index: true,
          indexLabel: "序号",
          translate: false,
          column: [
            {
              label: "讲师姓名",
              prop: "name",
              search: true,
              span: 8,
              width: "100px",
              rules: [
                {
                  required: true,
                  message: "请输入讲师姓名",
                  trigger: "blur",
                },
              ],
            },

            // {
            //   label: "讲师类型",
            //   prop: "teacher_category",
            //   props: {
            //     label: "label",
            //     value: "label",
            //   },
            //   dicUrl: `dictionaries/type/teacher.category`,
            //   type: "radio",
            //   rules: [
            //     {
            //       required: true,
            //       message: "请选择讲师类型",
            //       trigger: "blur",
            //     },
            //   ],
            //   hide: true,
            //   showColumn: false,
            //   span: 8,
            // },

            {
              label: "讲师类型",
              prop: "types",
              props: {
                label: "label",
                value: "label",
              },
              type: "cascader",
              dicUrl: `teachers/type`,
              multiple: true,
            },

            {
              label: "性别",
              prop: "gender",
              props: {
                label: "label",
                value: "label",
              },
              dicUrl: `dictionaries/type/teacher.gender`,
              type: "radio",
              hide: true,
              showColumn: false,
              span: 8,
            },

            {
              label: "等级",
              prop: "grade",
              search: this.IsPC,
              props: {
                label: "label",
                value: "label",
              },
              searchFilterable: true,
              width: "60px",
              dicUrl: `dictionaries/type/teacher.grade`,
              type: "radio",
              // showColumn: false,
              span: 8,
            },

            {
              label: "手机号",
              prop: "mobile",
              rules: [
                {
                  required: true,
                  message: "请填写手机号",
                  trigger: "blur",
                },
              ],
              // search: this.IsPC,
              search: false,
              span: 8,
              width: "120px",
              showColumn: false,
              hide: true,
            },

            {
              label: "邮箱",
              prop: "email",
              hide: true,
              showColumn: false,
              span: 8,
            },

            {
              label: "线下课酬",
              prop: "price",
              width: "120px",
              type: "number",
              // search: this.IsPC,
              search: false,
              span: 8,
              rules: [
                {
                  required: true,
                  message: "请输入讲师课酬",
                  trigger: "blur",
                },
              ],
            },

            {
              label: "线上课酬",
              prop: "onlinePrice",
              type: "number",
              // search: this.IsPC,
              search: false,
              span: 8,
              rules: [
                {
                  required: true,
                  message: "请输入讲师课酬",
                  trigger: "blur",
                },
              ],
            },

            // {
            //   label: "公开课报价(元/6小时)",
            //   prop: "onlinePrice",
            //   type: "number",
            //   // search: this.IsPC,
            //   span: 12,
            //   // rules: [
            //   //   {
            //   //     required: true,
            //   //     message: "请输入公开课对外报价(元/6小时)",
            //   //     trigger: "blur",
            //   //   },
            //   // ],
            // },

            // {
            //   label: "内训课报价(元/6小时)",
            //   prop: "onlinePrice",
            //   type: "number",
            //   // search: this.IsPC,
            //   span: 12,
            //   // rules: [
            //   //   {
            //   //     required: true,
            //   //     message: "请输入公开课对外报价(元/6小时)",
            //   //     trigger: "blur",
            //   //   },
            //   // ],
            // },
            {
              label: "授课领域",
              search: this.IsPC,
              props: {
                label: "label",
                value: "label",
              },
              prop: "field",
              type: "select",
              dicUrl: `dictionaries/type/schedule.field`,
              searchFilterable: true,
              span: 8,
              // hide: true,
            },
            {
              label: "合作属性",
              search: this.IsPC,
              props: {
                label: "label",
                value: "label",
              },
              prop: "teacher_type",
              dicUrl: `dictionaries/type/teacher.type`,
              type: "select",
              span: 8,
              // hide: true,
            },
            {
              label: "常驻城市",
              search: this.IsPC,
              prop: "resident_city",
              span: 8,
              width: "160px",
            },
            {
              label: "最高学历",
              prop: "educational",
              search: this.IsPC,
              span: 8,
              props: {
                label: "label",
                value: "label",
              },
              dicUrl: `dictionaries/type/teacher.educational`,
              type: "select",
              searchFilterable: true,
              span: 8,
              // hide: true,
            },

            {
              label: "讲师报价表",
              prop: "quotation",
              type: "dynamic",
              span: 24,
              hide: true,
              children: {
                align: "center",
                headerAlign: "left",
                rowAdd: (done) => {
                  this.$message.success("新增成功");
                  done({
                    // input: "默认值",
                  });
                },
                rowDel: (row, done) => {
                  this.$message.success("删除成功");
                  done();
                },
                column: [
                  {
                    width: 200,
                    label: "标题",
                    prop: "title",
                  },
                  {
                    label: "金额",
                    type: "number",
                    prop: "amount",
                  },
                  {
                    label: "单位",
                    prop: "unit",
                  },
                ],
              },
            },

            {
              label: "年度目标",
              type: "number",
              prop: "year_target",
              hide: true,
              showColumn: false,
              span: 8,
            },
            // {
            //   label: "课程目标",
            //   type: "number",
            //   prop: "course_target",
            //   hide: true,
            //   showColumn: false,
            //   span: 8,
            // },

            // {
            //   label: "常住地址",
            //   prop: "residence",
            //   type: "cascader",
            //   span: 8,
            //   props: {
            //     label: "value",
            //     value: "value",
            //   },
            //   formatter: (row, value) => value && value.join("-"),
            //   dicUrl: "getCity",
            // },
            {
              label: "详细地址",
              prop: "address",
              hide: true,
              showColumn: false,
              placeholder: "常住地详细地址(用于邮寄快递等)",
              span: 8,
            },

            {
              label: "毕业院校",
              prop: "industry",
              span: 8,
              hide: true,
              showColumn: false,
            },

            // {
            //   label: "讲师助理",
            //   prop: "assistant_user",
            //   props: {
            //     label: "username",
            //     value: "_id",
            //   },
            //   span: 8,
            //   type: "select",
            //   dicUrl: `users`,
            // },
            {
              label: "身份证姓名",
              prop: "cardName",
              span: 8,
              hide: true,
              showColumn: false,
            },
            {
              label: "身份证号码",
              prop: "cardNumber",
              span: 8,
              hide: true,
              showColumn: false,
            },
            {
              label: "银行卡号",
              prop: "BankNumber",
              span: 8,
              hide: true,
              showColumn: false,
            },
            {
              label: "开户行",
              prop: "bankName",
              span: 8,
              hide: true,
              showColumn: false,
            },
            {
              label: "紧急联系人",
              prop: "emergencyContact",
              span: 8,
              hide: true,
              showColumn: false,
            },
            {
              label: "紧急联系电话",
              prop: "emergencyCall",
              span: 8,
              hide: true,
              showColumn: false,
            },
            {
              label: "订票信息",
              prop: "bookingInfo",
              type: "textarea",
              span: 24,
              minRows: 2,
              hide: true,
              showColumn: false,
            },
            {
              label: "重要提醒",
              prop: "importantReminder",
              type: "textarea",
              span: 24,
              minRows: 2,
              hide: true,
              showColumn: false,
            },
            // {
            //   label: "提醒详情",
            //   prop: "remindDetail",
            //   type: "textarea",
            //   span: 24,
            //   minRows: 2,
            //   hide: true,
            //   showColumn: false,
            // },
            {
              label: "签约日期",
              prop: "contractDate",
              type: "daterange",
              startPlaceholder: "日期开始范围自定义",
              endPlaceholder: "日期结束范围自定义",
              hide: true,
              showColumn: false,
              span: 24,
            },
            // {
            //   label: "实战经验",
            //   prop: "experience",
            //   type: "textarea",
            //   span: 24,
            //   minRows: 2,
            //   showColumn: false,
            // },
            {
              label: "身份证照片",
              prop: "cardImage",
              type: "upload",
              dataType: "array",
              listType: "picture-card",
              action: "upload",
              span: 24,
              hide: true,
              showColumn: false,
              multiple: true,
            },
            {
              label: "讲师合同",
              prop: "contract",
              multiple: true,
              type: "upload",
              dataType: "array",
              listType: "picture-card",
              action: "upload",
              span: 24,
              hide: true,
              showColumn: false,
              display: this.user && this.user.role !== 1 && this.user.role !== 4,
            },
            {
              label: "合同附件",
              prop: "file",
              type: "upload",
              loadText: "附件上传中，请稍等",
              span: 18,
              hide: true,
              showColumn: false,
              action: "upload",
              display: this.user && this.user.role !== 1 && this.user.role !== 4,
            },
            //  {
            //   label: '合同附件上传',
            //   prop: 'file',
            //    type: 'upload',
            //    accept:"pdf/pdf",
            //   span: 24,
            //   drag: true,
            //   loadText: '附件上传中，请稍等',
            //   action: 'upload'
            // },
            {
              label: "形象照",
              prop: "photo",
              multiple: true,
              type: "upload",
              dataType: "array",
              listType: "picture-card",
              action: "upload",
              span: 24,
              hide: true,
              showColumn: false,
            },
            {
              label: "资质证书",
              prop: "certificate",
              multiple: true,
              type: "upload",
              dataType: "array",
              listType: "picture-card",
              action: "upload",
              limit: 999,
              span: 24,
              hide: true,
              showColumn: false,
            },
            {
              label: "讲师海报",
              prop: "poster",
              multiple: true,
              type: "upload",
              dataType: "array",
              listType: "picture-card",
              action: "upload",
              span: 24,
              hide: true,
              showColumn: false,
            },
            {
              label: "附件上传",
              prop: "attachment",
              type: "upload",
              loadText: "附件上传中，请稍等",
              span: 18,
              hide: true,
              showColumn: false,
              action: "upload",
            },
            {
              label: "讲师介绍",
              prop: "content",
              type: "input-html",
              span: 20,
              hide: true,
              showColumn: false,
            },
            {
              label: "",
              prop: "training_photos",
              type: "upload",
              formslot: true,
              dataType: "array",
              listType: "picture-card",
              action: "upload",
              span: 24,
              hide: true,
              showColumn: false,

              display: this.showPhotos,
            },
          ],
        };
      },

      teachoption() {
        return {
          border: true,
          dialogWidth: 900,
          menuWidth: 280,
          height: "auto",
          menu: false,
          viewBtn: false,
          addBtn: false,
          align: "center",
          searchMenuSpan: 24,
          searchShowBtn: false,
          refreshBtn: false,
          editBtn: false,
          delBtn: false,
          dialogClickModal: false,
          index: true,
          columnBtn: false,
          indexLabel: "序号",
          translate: false,
          column: [
            {
              label: "上课时间",
              prop: "times",
              span: 8,
              slot: true,
              search: true,
              searchslot: true,
              sortable: "custom",
              width: 120,
            },
            {
              label: "讲师",
              prop: "teacher",
              span: 24,
              type: "select",
              slot: true,
              // dicUrl: "teachers/option",
              searchFilterable: true,
              width: 80,
              props: {
                label: "name",
                value: "_id",
              },
            },
            {
              label: "课程名称",
              prop: "post",
              span: 8,
              width: "200px",
              search: true,
              slot: true,
            },

            {
              label: "授课方式",
              prop: "way",
              type: "select",
              span: 24,
              slot: true,
              searchFilterable: true,
              // search: true,
              width: 100,
            },
            {
              label: "总时长（小时/天）",
              prop: "fate",
              span: 8,
              width: "150px",
              slot: true,
            },

            {
              label: "授课类别",
              prop: "category",
              props: {
                label: "label",
                value: "label",
              },
              search: true,
              type: "select",
              dicUrl: `dictionaries/type/schedule.category`,

              span: 8,
            },

            {
              label: "授课城市",
              slot: true,
              prop: "city",
              filterable: true,
              search: true,
              type: "cascader",
              searchslot: true,
              props: {
                label: "value",
                value: "value",
              },
              span: 8,
              // dicData: this.cities,
              dicUrl: "",
              checkStrictly: false,
            },
            {
              label: "银行类别",
              prop: "bank_type",
              props: {
                label: "label",
                value: "label",
              },
              type: "select",
              dicUrl: `dictionaries/type/schedule.bank_type`,
              span: 8,
              searchFilterable: true,
              hide: true,
              showColumn: false,
              search: true,
            },
            {
              label: "终端名称",
              prop: "terminal_name",
              span: 8,
              showColumn: true,
              search: true,
              slot: true,
            },

            {
              label: "学员岗位",
              prop: "student_job",
              span: 8,
              showColumn: false,
              search: true,
              slot: true,
            },

            {
              label: "客户名称",
              searchFilterable: true,
              prop: "customer",
              slot: true,
              props: {
                label: "name",
                value: "_id",
              },
              span: 8,
              width: "200px",
              search: true,
              type: "select",
              dicUrl: "customers/option",
            },
            {
              label: "满意度",
              prop: "evaluate",
              type: "select",
              props: {
                label: "label",
                value: "value",
              },
              search: true,
              rules: [
                {
                  required: true,
                  message: "请选择满意度",
                  trigger: "blur",
                },
              ],
              span: 8,
              dicUrl: `dictionaries/type/schedule.satisfaction`,
            },

            {
              label: "创建人",
              searchFilterable: true,
              prop: "user",
              slot: true,
              props: {
                label: "realname",
                value: "_id",
              },
              span: 8,
              width: "140px",
              search: true,
              type: "select",
              dicUrl: "users/leader/option",
            },
          ],
        };
      },

      cur_time() {
        return (item) => {
          let times = item.times.filter((v) => dayjs(v).toDate() < new Date()).sort((a, b) => new Date(a) > new Date(b) ? -1 : 1);
          return times.join(",");
        };
      },
    },
    watch: {
      "customerQuery.year"() {
        console.log(this.customerQuery.year);
        this.teacherData(this.obj);
        this.fetchDetailData();

        const years = this.customerQuery.year.map((v) => {
          return dayjs(v).format("YYYY-MM");
        });

        let time_str = Array.from(new Set(years)).join("-");
        this.$message({
          message: `已为您切换至${time_str}年数据`,
          type: "success",
          align: "center",
          center: true,
        });
      },
    },
    methods: {
      handleCurrentChange(val) {
        this.customerQuery.page = val;
        this.teacherData(this.obj);
      },
      changeDetailPage(val) {
        this.detailPage.page = val;
        this.fetchDetailData();
      },
      sortDetailData(val) {
        const { prop, order } = val;
        this.sort_where = {};

        this.sort_where[prop] = order === "ascending" ? 1 : -1;
        console.log(this.sort_where, "sort_where");
        this.detailPage.page = 1;
        this.fetchDetailData();
      },
      getOssUrl(url) {
        return url + "?x-oss-process=image/resize,w_1000/quality,q_80";
      },
      async teachData(row) {
        this.isShowTeach = true;
        this.teach_user = row._id;
        this.fetchTeachList();
      },

      async fetchTeachList() {
        if (!this.teach_user) {
          return false;
        }
        this.teachLoading = true;
        const res = await this.$http.get(
          `teachers/teachList/${this.teach_user}`,
          {
            params: {
              query: {
                where: {
                  times: this.queryTimes,
                  city: this.queryCitys,
                  ...this.teachQuery.where,
                },
                sort: this.teachSort,
                limit: this.teachPage.pageSize,
                page: this.teachPage.currentPage,
              },
            },
          }
        );
        this.teachLoading = false;
        this.teachPage.total = res.data.total;
        this.teachList = res.data.data;
        this.year_evaluate = res.data.year_evaluate
      },
      async exportTeachList() {
        const { data } = await this.$http.get(
          `teachers/teachList/${this.teach_user}`,
          {
            params: {
              query: {
                where: {
                  times: this.queryTimes,
                  city: this.queryCitys,
                  ...this.teachQuery.where,
                },
                sort: this.teachSort,
                isExport: true,
              },
            },
            responseType: "arraybuffer",
          }
        );
        let blob = new Blob([data], { type: "application/x-xls" });
        saveAs(blob, `${dayjs().format("YYYY-MM-DD")}-授课记录数据.xlsx`);

        this.$message.success("导出成功")
      },
      closeTeach() {
        this.teachExport = false;
        this.teachForm = {
          teachers: [],
          times: []
        }
      },
      async teacherData(row) {
        this.obj = row;
        this.dialogVisible = true;
        const res = await this.$http.post(`teachers/teacherData/${row.id}`, {
          params: {
            query: JSON.stringify(this.customerQuery),
          },
        });
        this.customerPage.total = res.data.total;
        this.customers = res.data.cooperativeCustomers;

        this.teachingDay = res.data.teachingDay;
        this.customerCount = res.data.customer;
        this.userData = res.data.userData;
        this.teacherOption = res.data.teacherOption;
        // this.recommendData = res.data.recommend;

        this.teacherOption.data = this.teacherOption.data.map((v) => {
          if (v.key === "remain" || v.key === "receive" || v.key == "amount") {
            return {
              ...v,
              click: function (item) {
                const div_top = document.querySelector(".detail_table");
                if (div_top) {
                  div_top.scrollIntoView({ behavior: "smooth", block: "start" });
                }
              },
            };
          } else {
            return v;
          }
        });
        // this.coach = res.data.coach;
        // console.log(res.data.teachingDays)
        Promise.all([this.fetchDetailData()]);
        this.initChart1();
      },
      async fetchDetailData() {
        const res = await this.$http.get(
          `teachers/teacherDetailData/${this.obj.id}`,
          {
            params: {
              year: this.customerQuery.year,
              sort: this.sort_where,
              // ...(key=='remain'?this.remaindetailPage:this.receiveddetailPage),
              ...this.detailPage,
            },
          }
        );
        this.detailData = res.data.data;
        this.detailPage.total = res.data.total;
      },
      handleClose(done) {
        done();
      },

      handleTeacherClose() {
        this.isShowTeach = false;
      },
      async exportImgs() {
        this.dialogExport = true;
      },
      exportTeachs() {
        this.teachExport = true;
      },
      async exprot() {
        const res = await this.$http.post(
          "teachers/export/",
          {},
          {
            responseType: "arraybuffer",
          }
        );
        const link = document.createElement("a");
        let blob = new Blob([res.data], { type: "application/x-xls" });
        link.href = URL.createObjectURL(blob);
        const title = `${dayjs().format("YYYY")}年客户排课数据`;
        link.download = `${title}.xlsx`;
        link.click();
        document.body.appendChild(link);
      },
      preview(file, column, done) {
        const ext = file.url.split(".").pop();
        if (["jpg", "jpeg", "png", "gif"].includes(ext)) {
          done();
        } else {
          window.open(file.url);
        }
      },
      beforeClose(done) {
        this.photos = [];
        this.showPhotos = false;
        done();
      },
      sortChange(column) {
        const sortKey = column.prop;
        const sort_num = column.order == "descending" ? -1 : 1;
        this.teachSort = {
          [sortKey]: sort_num,
        };

        console.log("sort where")

        this.fetchTeachList();
      },
      async beforeOpen(done, type) {
        if (["view"].includes(type)) {
          const loading = this.$loading({
            lock: true,
            text: "数据加载中，请稍等～",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.showPhotos = true;
          // console.log(this.obj._id);
          const res = await this.$http.get(
            `teachers/training_photo/${this.obj._id}`
          );
          const photos = [];
          res.data.forEach((v) => {
            // console.log(v)
            if (v.training_photos.length > 0) {
              return photos?.push({
                times: v.times.sort((a, b) => Date.parse(a) - Date.parse(b)),
                url: v.training_photos,
                terminal_name: v.terminal_name,
              });
            }
          });

          this.photos = photos.sort(
            (a, b) =>
              Date.parse(b.times[b.times.length - 1]) -
              Date.parse(a.times[a.times.length - 1])
          );
          loading.close();
          // setTimeout(() => {
          //   this.obj.training_photos = this.photos;
          // }, 0);
          // 查看逻辑
        } else if (["edit"].includes(type)) {
          if ([0, 6].includes(this.user.role)) {
            if (this.obj.quotation.length == 0) {
              this.obj.quotation = this.quotation;
            }
          } else {
            this.$message.error("无操作权限");
            return;
          }
          //  setTimeout(() => {
          // this.obj.quotation.push({

          // })
          //  }, 0);
        } else if (["add"].includes(type)) {
          this.obj.quotation = this.quotation;
        } else {
          if (this.user.role !== 0) {
            this.$message.error("无操作权限");
            return;
          }
          //新增逻辑
          //一定要用setTimeout包裹，由于form组件底层一些机制的设计
          // setTimeout(() => {
          //   this.form.name = "初始化赋值";
          // }, 0);
        }
        done();
      },
      async doImport() {
        if (this.importData.length > 0) {
          const loading = this.$loading({
            lock: true,
            text: "导入中，请稍后。请勿关闭刷新页面",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          await this.$http.post("teachers/import", this.importData);
          loading.close();
          this.fetch();
          this.$message.success("导入成功");
        } else {
          this.$message.error("上传文件为空");
        }
      },
      async parseItems(e) {
        const file = e.target.files[0];
        const { results } = await this.$Export.xlsx(file);
        this.$message.success("上传成功,请导入");
        const items = results.map((v) => {
          return {
            name: v["讲师姓名"],
            gender: v["性别"],
            teacher_category: v["讲师等级"],
            grade: v["讲师等级"],
            teacher_type: v["合作属性"],
            field: v["授课领域"],
            price: Number(v["讲师课酬"]),
            resident_city: v["常驻城市"],
            address: v["详细地址"],
            mobile: v["手机号码"],
            email: v["邮箱"],
            cardName: v["身份证姓名"],
            cardNumber: v["身份证号码"],
            educational: v["最高学历"],
            industry: v["毕业院校"],
            BankNumber: v["银行卡号"],
            bankName: v["开户行"],
            contractDate: v["签约日期"],
            bookingInfo: v["订票信息"],
            content: v["讲师介绍"],
            user: this.user._id,
          };
        });
        this.importData = items;
      },
      async changPage({ pageSize, currentPage }) {
        this.query.page = currentPage;
        this.query.limit = pageSize;
        this.fetch();
      },
      async changTeachPage({ pageSize, currentPage }) {
        this.teachPage.currentPage = currentPage;
        this.teachPage.pageSize = pageSize;

        this.fetchTeachList();
      },
      teachSearch(where, done) {
        console.log(where, "where");
        console.log(this.teachQuery);

        this.teachQuery.where = {
          ...where,
        };
        console.log(this.teachQuery);
        this.fetchTeachList();
        done();
      },
      async search(where, done) {
        done();
        if (where.name) {
          where.name = { $regex: where.name };
        }
        if (where.mobile) {
          where.mobile = { $regex: where.mobile };
        }
        if (where.grade) {
          where.grade = { $regex: where.grade };
        }
        if (where.price) {
          where.price = where.price;
        }
        if (where.resident_city) {
          where.resident_city = { $regex: where.resident_city };
        }
        if (where.educational) {
          where.educational = { $regex: where.educational };
        }
        if (where.teacher_type) {
          where.teacher_type = { $regex: where.teacher_type };
        }
        if (where.field) {
          where.field = { $regex: where.field };
        }
        //   if (this.user.role == 7) {
        //    this.teachersId = {
        //       _id: { $in: this.user.teachers},
        //     }
        // }
        this.query.where = where;
        this.fetch();
      },
      async fetchTeachers() {
        const res = await this.$http.get("teachers/option");
        this.teachers_options = res.data.map((v) => {
          return {
            label: v.name,
            value: v._id,
          };
        });
      },
      async fetch() {
        const res = await this.$http.get("teachers", {
          params: {
            query: {
              ...this.query,
              where: {
                ...(this.query.where ?? {}),
                // is_delete:0
              },
            },
          },
        });
        this.page.total = res.data.total;
        this.data = res.data;
      },
      async initChart1() {
        let chartDom = this.$refs.chart1;
        // let chartDom2 = this.$refs.chart2;
        let chartDom3 = this.$refs.chart3;
        // let chartDom4 = this.$refs.chart4;
        let myChart = this.$echarts.init(chartDom);
        // let myChart2 = this.$echarts.init(chartDom2);
        let myChart3 = this.$echarts.init(chartDom3);
        // let myChart4 = this.$echarts.init(chartDom4);
        myChart.setOption(this.teachingDay, true);
        // myChart2.setOption(this.customerCount, true);
        myChart3.setOption(this.userData, true);
        // myChart4.setOption(this.recommendData, true);
        window.addEventListener("resize", () => {
          myChart.resize();
          // myChart2.resize();
          myChart3.resize();
        })
      },
      async remove(row) {
        if ([0, 6].includes(this.user.role)) {
          try {
            await this.$confirm("是否确认删除？");
          } catch (e) {
            return;
          }
          await this.$http.delete(`teachers/${row._id}`);
          this.$message.success("删除成功");
          this.fetch();
        } else {
          this.$message.error("无操作权限");
        }
      },
      async exportSubmit(row, done) {
        if (row.teacherIds.length > 10) {
          this.$message.error("一次最多可导出10个老师");
          done();
        } else {
          const { data } = await this.$http.post("/teachers/batch/exports", row);
          this.dialogExport = false;
          saver.download(
            data,
            () => {
              this.$message.success("下载成功");
              this.dialogExport = false;
            },
            () => {
              this.$message.error("下载失败,请重试");
            }
          );
          done();
        }
      },
      async teachSubmit(row, done) {
        this.$message.success("提交成功，...正在导出", {
          duration: 500
        })
        const { data } = await this.$http.post("teachers/exportTeachList/all", {
          ...row
        }, {
          responseType: "arraybuffer",
        })
        done()
        let blob = new Blob([data], { type: "application/x-xls" });
        saveAs(blob, `${dayjs().format("YYYY-MM-DD")}-授课记录数据.xlsx`);
        this.$message.success("导出成功")
        this.closeTeach()
      },
      // async exportSubmit(row, done) {
      //   const baseUrl="http://localhost:3122/api/"
      //   const token = localStorage.getItem("token") || "";
      //   const tenant = localStorage.getItem("tenant") || "";
      //   const headers = {
      //     Authorization: "Bearer " + token,
      //     "x-tenant": tenant,
      //   };
      //   await fetch(`${baseUrl}teachers/batch/exports`,row, {
      //     method: "POST",
      //     mode: "cors",
      //     headers,
      //   }).then((res) => {
      //     let contentDisposition = res.headers.get("Content-Disposition");
      //     let fileName = contentDisposition.substring(
      //       contentDisposition.lastIndexOf("=") + 1
      //     );
      //     if (!window.WritableStream) {
      //       streamSaver.WritableStream = WritableStream;
      //       window.WritableStream = WritableStream;
      //     }
      //     const fileStream = streamSaver.createWriteStream(fileName);
      //     const readableStream = res.body;
      //     if (readableStream.pipeTo) {
      //       return readableStream.pipeTo(fileStream);
      //     }
      //     window.writer = fileStream.getWriter();
      //     const reader = res.body.getReader();
      //     console.log(22222)
      //     const pump = () =>
      //       reader
      //         .read()
      //         .then((res) =>
      //           res.done ? writer.close() : writer.write(res.value).then(pump)
      //         );
      //     pump();

      //     console.log("end")

      //   });
      // },

      async fetchCity() {
        const { data } = await this.$http.get("getCity");
        this.cityOptions = data;
      },
      async update(row, index, done) {
        if ([0, 6].includes(this.user.role)) {
          this.$clear(this.obj);
          // console.log(row)
          await this.$http.put(`teachers/${this.obj._id}`, this.obj);
          this.$message.success("更新成功");
          this.fetch();
          this.$http.post(`teachers/update/record`, this.obj);
          done();
        } else {
          this.$message.error("无操作权限");
        }
      },
      async create(row, done) {
        try {
          await this.$http.post("teachers/isExist", row);
          const data = await this.$http.post("teachers", row);
          this.$http.post(`teachers/update/record`, data);
          this.$message.success("创建成功");
          this.fetch();
          done();
        } catch {
          // done();
        }
      },
    },
    created() {
      this.fetch();
      this.fetchCity();
      this.fetchTeachers();
    },
  };
</script>

<style>
  .avue-data-panel .item {
    padding: 0 20px !important;
  }

  .avue-data-panel .item-icon .iconfont {
    font-size: 40px;
  }

  .label-title {
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    padding-bottom: 1rem;
  }

  .el-timeline {
    padding: 0 !important;
  }

  .el-timeline .el-image__inner {
    border-radius: 0.4rem !important;
    border: 1px solid #ccc;
  }

  /* .el-textarea_inner {
  color: #000 !important;
}

.el-input__inner{
  color: #000 !important;
} */

  .avue--view .el-input.is-disabled .el-input__inner {
    color: #000 !important;
    opacity: 1 !important;
    transform: none !important;
    cursor: text !important;
  }

  .avue--view .el-textarea.is-disabled .el-textarea__inner {
    color: #000 !important;
    opacity: 1 !important;
    transform: none !important;
    cursor: text !important;
  }

  .el-dialog__body .avue-data-panel .item {
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 768px) {
    .chart-box {
      flex-wrap: wrap
    }

    .chart-box .chart1 {
      width: 100%;
    }

    .chart-box .chart2 {
      width: 100%;
    }

  }
</style>
