<template>
  <div>
    <el-table class="my-4" :data="customer_data" style="width: 100%">
      <el-table-column label="编号" width="180">
        <template slot-scope="scope">
          <p>{{ scope.$index + 1 }}</p>
        </template>
      </el-table-column>
      <el-table-column label="机构名称">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>

      <el-table-column label="今年合作金额">
        <template slot-scope="scope">
          {{ formatMoney(scope.row.count) }}
        </template>
      </el-table-column>

      <el-table-column label="负责人">
        <template slot-scope="scope">
          {{ scope.row.users.join(",") }}
        </template>
      </el-table-column>
    </el-table>
    <div
      :id="item.id"
      v-for="item in items"
      :key="item.id"
      v-loading="item.loading"
    >
      <div class="action p-3 d-flex justify-content-end">
      </div>
      <div
        class="chart"
        :ref="item.id"
        :style="{
          height: item.height + 'px',
          width: '100%',
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { formatMoney } from "../filters";

export default {
  data() {
    return {
      items: [
        // {
        //   id: "company_chart",
        //   height: 320,
        //   title: "机构排名",
        //   type: "bar",
        //   tooltip: {
        //     trigger: "axis",
        //     axisPointer: {
        //       type: "shadow",
        //     },
        //   },
        //   loading: false,
        //   grid: {
        //     left: "3%",
        //     right: "4%",
        //     bottom: "3%",
        //     containLabel: true,
        //   },
        //   download: false,
        // },

     
        {
          id: "bank_chart",
          height: 480,
          title: "银行排名",
          type: "pie",

          tooltip: {
            trigger: "item",
            axisPointer: {
              type: "shadow",
            },
            formatter: "{b}:{d}% ( {c} )",
          },
          download: false,
          loading: false,
        },
        {
          id: "city_chart",
          height: 780,
          title: "地区排名",
          loading: false,
          type: "pie",
          download: false,

          tooltip: {
            trigger: "item",
            axisPointer: {
              type: "shadow",
            },
            formatter: "{b}:{d}% ( {c} )",
          },
        },
        {
          id: "category_chart",
          height: 480,
          title: "授课类型",
          loading: false,
          type: "pie",
          download: false,

          tooltip: {
            trigger: "item",
            axisPointer: {
              type: "shadow",
            },
            formatter: "{b}:{d}% ( {c} )",
          },
        },
        {
          id: "course_chart",
          height: 320,
          title: "课量排名",
          type: "bar",
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          loading: false,
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          download: false,
        },
      ],
      chart_data: {},
      company_chart: null,
      city_chart: null,
      bank_chart: null,
      category_chart: null,
      customer_data: [],
    };
  },

  watch: {
    query: {
      deep: true,
      handler(nval, oval) {
        this.fetchData();
        this.fetchCustomerData();
      },
    },
  },

  props: {
    query: {
      type: Object,
    },
  },
  methods: {
    formatMoney(val) {
      return formatMoney(val);
    },
    async fetchItem(item) {
      item.loading = true;
      const { data } = await this.$http.post(`home/schedule_chart_data`, {
        key: item.id,
        query: this.query,
      });
      this.chart_data[item.id] = data;
    },
    async exportData(item) {
      item.download = true;
      const res = await this.$http.post(
        `home/schedule_chart_data`,
        {
          key: item.id,
          is_export: true,
        },
        {
          responseType: "arraybuffer",
        }
      );
      item.download = false;
      const contentDisposition = res.headers["content-disposition"];
      let filename = "";
      if (contentDisposition) {
        const matches = contentDisposition.match(/filename="(.+)"/);
        if (matches.length > 1) {
          filename = matches[1];
        }
      }

      let types = {
        company_chart: "客户数据",
        city_chart: "地区分类数据",
        bank_chart: "银行分类数据",
        category_chart: "授课类型数据",
      };
      saveAs(
        new Blob([res.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        `${types[item.id]}-${filename}`
      );

      this.$message.success("导出成功");
    },
    async fetchData() {
      await Promise.all(this.items.map((v) => this.fetchItem(v)));
      this.$nextTick(() => {
        this.items.map((v) => {
          const data = this.chart_data[v.id];
          if (data) {
            const opt = this.getBaseOptions(v, data);
            const dom = this.$refs[v.id][0];
            const c = this.$echarts.init(dom);
            c.setOption(opt, true);
            v.loading = false;
          }
        });
      });
    },

    async fetchCustomerData() {
      const { data } = await this.$http.post(`home/schedule_chart_data`, {
        key: "company_chart",
        query: this.query,
      });
      this.customer_data = data;

    },

    getBaseOptions(item, data) {
      return {
        title: {
          text: item.title,
          style: {
            fontSize: 12,
            center: true,
          },
        },
        toolbox:{
            feature:{
                saveAsImage: {},
            },
            show:true, 
        },
        legend: {
          padding: [60, 20, 0, 0],
          y: "center",
          x: "right",
          orient: "vertical",
          textStyle: {
            lineHeight: 14,
            fontSize: 12,
          },
          itemGap: 16,
          formatter: (val) => {
            let result = "";
            const textLength = val.length;
            const limitNumber = 4; // 一行显示几个字
            if (textLength > limitNumber) {
              for (var i = 0; i < textLength; i += limitNumber) {
                result += val.substring(i, i + limitNumber) + "\n";
              }
              result = result.trim(); // 移除最后一个换行符
            } else {
              result = val;
            }
            return result;
          },
        },
        tooltip: item.tooltip,
        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          show: item.type == "bar",
          data: data.names || [],
          //   文本换行
          axisLabel: {
            formatter: (val) => {
              let result = "";
              const textLength = val.length;
              const limitNumber = 4; // 一行显示几个字
              if (textLength > limitNumber) {
                for (var i = 0; i < textLength; i += limitNumber) {
                  result += val.substring(i, i + limitNumber) + "\n";
                }
                result = result.trim(); // 移除最后一个换行符
              } else {
                result = val;
              }
              return result;
            },
            interval: 0,
            rotate: 40,
          },
        },
        grid: item.grid || {},

        yAxis: {
          type: "value",
          show: item.type == "bar",
          name: '天',
        },
        series: [
          {
            data: data.values,
            type: item.type,
            showBackground: true,
            center: ["30%", "60%"],
            avoidLabelOverlap: true, //对，就是这里avoidLabelOverlap
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      };
    },
  },

  created() {},

  mounted() {
    this.fetchData();
    this.fetchCustomerData();
  },
};
</script>
