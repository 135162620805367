<template>
  <div>
    <div :id="item.id" v-for="item in items" :key="item.id" v-loading="item.loading">
      <div class="action p-3 d-flex justify-content-end"></div>
      <div
        class="chart"
        :ref="item.id"
        :style="{
          height: item.height + 'px',
          width: '100%',
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { formatMoney } from "../filters";

export default {
  data() {
    return {
      items: [
        // {
        //   id: "company_chart",
        //   height: 320,
        //   title: "机构排名",
        //   type: "bar",
        //   tooltip: {
        //     trigger: "axis",
        //     axisPointer: {
        //       type: "shadow",
        //     },
        //   },
        //   loading: false,
        //   grid: {
        //     left: "3%",
        //     right: "4%",
        //     bottom: "3%",
        //     containLabel: true,
        //   },
        //   download: false,
        // },

        {
          id: "bank_chart",
          height: 480,
          title: "银行排名",
          type: "pie",

          tooltip: {
            trigger: "item",
            axisPointer: {
              type: "shadow",
            },
            formatter: "{b}:{d}% ( {c} )",
          },
          download: false,
          loading: false,
        },
        {
          id: "city_chart",
          height: 780,
          title: "地区排名",
          loading: false,
          type: "pie",
          download: false,

          tooltip: {
            trigger: "item",
            axisPointer: {
              type: "shadow",
            },
            formatter: "{b}:{d}% ( {c} )",
          },
        },
        {
          id: "category_chart",
          height: 480,
          title: "授课类型",
          loading: false,
          type: "pie",
          download: false,

          tooltip: {
            trigger: "item",
            axisPointer: {
              type: "shadow",
            },
            formatter: "{b}:{d}% ( {c} )",
          },
        },
        {
          id: "course_chart",
          height: 320,
          title: "课量排名",
          type: "bar",
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          loading: false,
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          download: false,
        },
      ],
      chart_data: {},
      company_chart: null,
      city_chart: null,
      bank_chart: null,
      category_chart: null,
      customer_data: [],
    };
  },

  watch: {
    query: {
      deep: true,
      handler(nval, oval) {
        this.fetchData();
        this.fetchCustomerData();
      },
    },
    customer(){
      this.fetchData()
    }
  },

  props: {
    query: {
      type: Object,
    },
    customer: {
      type: String,
    },
  },
  methods: {
    formatMoney(val) {
      return formatMoney(val);
    },
    async fetchItem(item) {
      item.loading = true;
      const { data } = await this.$http.get(`customers/analyze`, {
        params: {
          key: item.id,
          customer: this.customer,
          query: this.query,
        },
      });
      this.chart_data[item.id] = data;
    },
    async fetchData() {
      await Promise.all(this.items.map((v) => this.fetchItem(v)));
      this.$nextTick(() => {
        this.items.map((v) => {
          const data = this.chart_data[v.id];
          if (data) {
            setTimeout(() => {
              const opt = this.getBaseOptions(v, data);
              const dom = this.$refs[v.id][0];
              const c = this.$echarts.init(dom);
              c.setOption(opt, true);
              v.loading = false;
            }, 0);
          }
        });
      });
    },
    getBaseOptions(item, data) {
      return {
        title: {
          text: item.title,
          style: {
            fontSize: 12,
            center: true,
          },
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
          show: true,
        },
        legend: {
          padding: [60, 20, 0, 0],
          y: "center",
          x: "right",
          orient: "vertical",
          textStyle: {
            lineHeight: 14,
            fontSize: 12,
          },
          itemGap: 16,
          formatter: (val) => {
            let result = "";
            const textLength = val.length;
            const limitNumber = 4; // 一行显示几个字
            if (textLength > limitNumber) {
              for (var i = 0; i < textLength; i += limitNumber) {
                result += val.substring(i, i + limitNumber) + "\n";
              }
              result = result.trim(); // 移除最后一个换行符
            } else {
              result = val;
            }
            return result;
          },
        },
        tooltip: item.tooltip,
        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          show: item.type == "bar",
          data: data.names || [],
          //   文本换行
          axisLabel: {
            formatter: (val) => {
              let result = "";
              const textLength = val.length;
              const limitNumber = 4; // 一行显示几个字
              if (textLength > limitNumber) {
                for (var i = 0; i < textLength; i += limitNumber) {
                  result += val.substring(i, i + limitNumber) + "\n";
                }
                result = result.trim(); // 移除最后一个换行符
              } else {
                result = val;
              }
              return result;
            },
            interval: 0,
            rotate: 40,
          },
        },
        grid: item.grid || {},

        yAxis: {
          type: "value",
          show: item.type == "bar",
          name: "天",
        },
        series: [
          {
            data: data.values,
            type: item.type,
            showBackground: true,
            center: ["30%", "40%"],
            avoidLabelOverlap: true, //对，就是这里avoidLabelOverlap
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      };
    },
  },

  created() {},

  mounted() {
    this.fetchData();
    // this.fetchCustomerData();
  },
};
</script>
